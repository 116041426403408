<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        inline
        :rules="searchRules"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item prop="countryCodeList">
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterCountryMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="agencyList">
          <el-select
            v-model="formData.agencyList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterAgencyMethod"
            collapse-tags
            placeholder="Agency"
            @visible-change="filterAgencyList = agencyList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('agencyList', agencyList, formData)"
              >
                {{ selectButtonLabel(formData.agencyList, agencyList) }}
              </div>
            </div>
            <el-option
              v-for="agency in filterAgencyList"
              :key="agency"
              :label="agency"
              :value="agency"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="mediaSourceList">
          <el-select
            v-model="formData.mediaSourceList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMediaSourceMethod"
            collapse-tags
            placeholder="Media Source"
            @visible-change="filterMediaSourceList = mediaSourceList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('mediaSourceList', mediaSourceList, formData)"
              >
                {{ selectButtonLabel(formData.mediaSourceList, mediaSourceList) }}
              </div>
            </div>
            <el-option
              v-for="media in filterMediaSourceList"
              :key="media"
              :label="media"
              :value="media"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            Search
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>

    <div
      v-loading="listInfo.loading"
      class="overview"
    >
      <div class="items">
        <div class="num">
          {{ listInfo.sumLtv1 }}
        </div>
        <div class="name">
          Sum(LTV 1) USD
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.sumCost }}
        </div>
        <div class="name">
          Sum(Cost) USD
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.sumRoi }}
        </div>
        <div class="name">
          Sum(LTV 1) / Sum(Cost)
        </div>
      </div>
    </div>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
    >
      <el-table-column
        label="Date"
        prop="dt"
        min-width="120px"
      />
      <el-table-column
        label="Country"
        prop="country"
        min-width="180px"
      />
      <el-table-column
        label="Channel"
        prop="channel"
        :formatter="channelFormatter"
        min-width="120px"
      />
      <el-table-column
        label="Agency"
        prop="agency"
        min-width="120px"
      />
      <el-table-column
        label="Media Source"
        prop="mediaSource"
        min-width="120px"
      />
      <el-table-column
        label="LTV 1"
        prop="ltv1"
        min-width="120px"
      />
      <el-table-column
        label="LTV 30"
        prop="ltv30"
        min-width="120px"
      />
      <el-table-column
        label="Cost(USD)"
        prop="cost"
        min-width="120px"
      />
      <el-table-column
        label="LTV 1 ROI"
        prop="ltv1Roi"
        min-width="120px"
      />
      <el-table-column
        label="LTV 30 ROI"
        prop="ltv30Roi"
        min-width="120px"
      />
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import { format } from 'fecha'
import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'
import {
  getAllAgencyList,
  getAllMediaSourceList,
  getFinancialAgencyList
} from '@/api/finance.js'
import {
  cloneDeep
} from 'lodash'

const getYesterday = () => {
  const hours = new Date().getHours()
  // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
  const days = hours > 9 ? 1 : 2
  const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
}
const formKey = 'AGENCY_FORM'

export default {
  name: 'DataWithAgency',
  data () {
    return {
      // 下拉框数据
      nationList: [],
      filterNationList: [],
      agencyList: [],
      filterAgencyList: [],
      mediaSourceList: [],
      filterMediaSourceList: [],

      formData: {
        dateRange: getYesterday(),
        countryCodeList: [],
        agencyList: [],
        mediaSourceList: [],
        pageNum: 1,
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          const hours = new Date().getHours()
          const days = hours > 9 ? 1 : 2
          const dateTime = time.getTime()
          return dateTime < new Date('2021-08-20').getTime() || dateTime > new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
        }
      },
      channelMap: {
        1: 'Android',
        2: 'iOS',
        3: 'All Channel'
      },
      listInfo: {
        list: [],
        total: 0,
        sumLtv1: 0,
        sumCost: 0,
        sumRoi: 0,
        loading: true
      },
      searchRules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ],
        countryCodeList: [
          { required: true, message: 'Country is required' }
        ],
        agencyList: [
          { required: true, message: 'Agency is required' }
        ],
        mediaSourceList: [
          { required: true, message: 'Media Source is required' }
        ]
      }
    }
  },
  mounted () {
    Promise.all([
      this.queryNationList(),
      this.queryAgencyList(),
      this.queryMediaSourceList()
    ]).then(() => {
      const storageFormData = sessionStorage.getItem(formKey)
      if (storageFormData) {
        this.listInfo.total = 9999
        this.formData = JSON.parse(storageFormData)
      }
      this.queryDataList()
    })
  },
  methods: {
    numFormatter (row, column, cellValue, index) {
      return !isNaN(cellValue) ? cellValue : '-'
    },
    channelFormatter (row, column, cellValue, index) {
      return this.channelMap[cellValue] || '-'
    },
    filterCountryMethod (keywords) {
      this.filterNationList = this.filterList(keywords, this.nationList, 'nation')
    },
    filterAgencyMethod (keywords) {
      this.filterAgencyList = this.agencyList.filter(item => item.toLowerCase().startsWith(keywords.toLowerCase()))
    },
    filterMediaSourceMethod (keywords) {
      this.filterMediaSourceList = this.mediaSourceList.filter(item => item.toLowerCase().startsWith(keywords.toLowerCase()))
    },

    filterList (keywords, list, field) {
      return keywords ? list.filter(item => {
        return item[field].toLowerCase().startsWith(keywords.toLowerCase())
      }) : list
    },

    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },

    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        startTime,
        endTime,
        countryCodeList: form.countryCodeList.map(item => {
          return item.countryCode
        })
      }
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          const query = this.formatQuery(this.formData)
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          getFinancialAgencyList(query)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list || []
                this.listInfo.total = res.data.total || 0
                this.listInfo.sumCost = res.data.sumCost
                this.listInfo.sumLtv1 = res.data.sumLtv1
                this.listInfo.sumRoi = res.data.sumRoi
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation.toLowerCase() < b.nation.toLowerCase()) {
              return -1
            } else {
              return 1
            }
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    },
    queryAgencyList () {
      return getAllAgencyList({})
        .then(res => {
          if (res.code === 200) {
            const sortAgencyList = (res.data.list || []).sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1
              } else {
                return 1
              }
            })
            this.agencyList = sortAgencyList
            this.filterAgencyList = sortAgencyList
            this.formData.agencyList = this.agencyList
          }
        })
    },
    queryMediaSourceList () {
      return getAllMediaSourceList({}).then(res => {
        if (res.code === 200) {
          const sortMediaSourceList = (res.data.list || []).sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1
            } else {
              return 1
            }
          })
          this.mediaSourceList = sortMediaSourceList
          this.filterMediaSourceList = sortMediaSourceList
          this.formData.mediaSourceList = this.mediaSourceList
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }
    }
    .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
</style>
